/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrandView_viewer$ref = any;
export type routes_BrandView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_BrandView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrandView_viewer$ref
  |}
|};
export type routes_BrandView_Query = {|
  variables: routes_BrandView_QueryVariables,
  response: routes_BrandView_QueryResponse,
|};
*/


/*
query routes_BrandView_Query(
  $ids: [ID]
) {
  viewer {
    ...BrandView_viewer
    id
  }
}

fragment BrandForm_viewer on Admin {
  id
  ...BrandHistory_viewer
  ...UrlRefresher_viewer
}

fragment BrandHistory_brand on Brand {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment BrandHistory_viewer on Admin {
  id
}

fragment BrandView_viewer on Admin {
  ...BrandForm_viewer
  brands(first: 1, ids: $ids) {
    edges {
      node {
        id
        name
        imageUrl
        urlSlug
        status
        metaTitle
        metaDescription
        description
        description2
        expressShipping
        privateLabel
        searchScoreMultiplier
        popular
        ...BrandHistory_brand
        ...WebsiteRef_brand
      }
    }
  }
}

fragment UrlRefresher_viewer on Admin {
  id
  username
}

fragment WebsiteRef_brand on Brand {
  websiteReference {
    id
    country
  }
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BrandView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BrandView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_BrandView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urlSlug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metaTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metaDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expressShipping",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "privateLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "searchScoreMultiplier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "popular",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "concreteType": "HistoryConnection",
                        "kind": "LinkedField",
                        "name": "histories",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HistoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "History",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "changes",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "insertedAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "histories(first:10)"
                      },
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "BrandHistory_histories",
                        "kind": "LinkedHandle",
                        "name": "histories"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebsiteReference",
                        "kind": "LinkedField",
                        "name": "websiteReference",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b15da9024f8ebeff7d94042523503f8",
    "id": null,
    "metadata": {},
    "name": "routes_BrandView_Query",
    "operationKind": "query",
    "text": "query routes_BrandView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...BrandView_viewer\n    id\n  }\n}\n\nfragment BrandForm_viewer on Admin {\n  id\n  ...BrandHistory_viewer\n  ...UrlRefresher_viewer\n}\n\nfragment BrandHistory_brand on Brand {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment BrandHistory_viewer on Admin {\n  id\n}\n\nfragment BrandView_viewer on Admin {\n  ...BrandForm_viewer\n  brands(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        name\n        imageUrl\n        urlSlug\n        status\n        metaTitle\n        metaDescription\n        description\n        description2\n        expressShipping\n        privateLabel\n        searchScoreMultiplier\n        popular\n        ...BrandHistory_brand\n        ...WebsiteRef_brand\n      }\n    }\n  }\n}\n\nfragment UrlRefresher_viewer on Admin {\n  id\n  username\n}\n\nfragment WebsiteRef_brand on Brand {\n  websiteReference {\n    id\n    country\n  }\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66ca3ddcc65697c125a378d38da3b09f';

module.exports = node;
