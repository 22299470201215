import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId, genCsv } from '../../helper';
import { BooleanDropdownFilter, Grid, iDGetter, valueGetter } from '../grid';
import { ExportBrandsMutation } from './mutations';

const entityName = 'Brand';

class BrandList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      environment: PropTypes.shape({}).isRequired,
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/brand/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 200,
      },
      {
        colId: 'expressShipping', headerName: 'Priority Shipping', valueGetter: valueGetter.bind(this, 'expressShipping'), width: 140, ...BooleanDropdownFilter,
      },
      {
        colId: 'urlSlug', headerName: 'URL', valueGetter: valueGetter.bind(this, 'urlSlug'), width: 200,
      },
      { colId: 'privateLabel', headerName: 'Private Label', valueGetter: valueGetter.bind(this, 'privateLabel'), ...BooleanDropdownFilter },
      { colId: 'popular', headerName: 'Popular', valueGetter: valueGetter.bind(this, 'popular'), ...BooleanDropdownFilter },
      { colId: 'status', headerName: 'Status', valueGetter: valueGetter.bind(this, 'status'), ...BooleanDropdownFilter },
    ];

    this.datasource = Grid.getDataSource.call(this, 'brands');

    this.state = {
      gridApi: null,
    };

  }

  handleSubmit = () => {
    const brandFilters = this.state.gridApi.getFilterModel();
    const filters = Object.keys(brandFilters).map(i => {
      const f = brandFilters[i];

      return {
        field: i,
        filter: String(f.filter),
        filterType: f.filterType,
        type: f.type
      };
    });

    const getFilename = (timeNow) => {
      const { COUNTRY } = process.env;

      return `${COUNTRY}-Export-Brands-${timeNow}`;
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { filters } },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const { result } = resp.exportBrands;

        if (result.length > 0) {
          const csvHeaders = ["id", "name", "priority_shipping", "url_slug", "private_label", "status"];

          genCsv(
            result,
            csvHeaders,
            getFilename,
          );
        }
      }
    }

    ExportBrandsMutation.commit(mutation);
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/brand/new"><Button>Add Brand</Button></Link>
        <Button
          style={{marginLeft: '10px'}}
          onClick={this.handleSubmit}
        >
          Download
        </Button>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          onGridReady={({api: gridApi}) => this.setState({gridApi})}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  BrandList, {
    viewer: graphql`
    fragment BrandList_viewer on Admin {
      brands(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "BrandList_brands") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            urlSlug
            status
            expressShipping
            privateLabel
            popular
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.brands;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query BrandListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...BrandList_viewer
      }
    }
  `,
  },
);
