import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { Button, message, Select, Switch, Table, Tabs } from 'antd';
import moment from 'moment-timezone';
import { DatePicker } from '~/components/form';
import { CurrencyFormatter, TIMEZONE } from './helper';

const { Option } = Select;
const { TabPane } = Tabs;

const entityName = 'Sales Target Report';

class SalesTargetReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesTargetReport: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      params: PropTypes.shape({
      }).isRequired,
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { match, viewer } = this.props;
    const tableSource = get(this.props.viewer, 'salesTargetReport.data', []);
    const { filterBy, isMgr } = match.route.prepareVariables();
    const [ { filter: dateFilter }] = filterBy;
    const initDateRange = dateFilter.split(',').map(d => moment(d));

    this.dateRange = initDateRange;
    this.isMgr = isMgr;
    this.fetchedDateRange = '';
    this.dateRangePickerRef = React.createRef();

    const { filter: branches } = filterBy.find(item => item.field === 'branches') || {};
    this.branchCodeMgr = (branches || "").split(",").filter(i => i);
    const allStores = get(viewer, 'stores.edges', []);
    this.stores = this.branchCodeMgr.length > 0 ? allStores.filter(({ node }) => this.branchCodeMgr.includes(node.branchCode)) : allStores;

    const allStaff = get(viewer, "staff", []);
    this.staff= this.branchCodeMgr.length > 0 ? allStaff.filter(member => this.branchCodeMgr.includes(member.branch_code)) : allStaff;


    this.state = {
      activeKey: "Store",
      loading: false,
      locations: [],
      states: [],
      staff: [],
      tableSource,
      currentDates: null,
      showInactStaff: false,
    };
  }

  onDownload = () => {
    const dlVariables = JSON.stringify(this.prepareVariables());
    const downloadUrl = `/api/report/sales-target/download?filters=${encodeURIComponent(dlVariables)}`;
    window.open(downloadUrl, '_blank', 'noopener,noreferrer');
  };

  onDateChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  onClickThisMonth = () => {
    const end = moment().subtract(1, 'days').endOf('day');
    const start = moment().startOf('month');
    this.dateRange = [start, end];

    if (this.dateRangePickerRef.current) {
      this.dateRangePickerRef.current.blur();
    }

    this.refetch(this.dateRange);
  }

  onCurrentDateChange = (currentDates) => {
    this.setState({currentDates});
  }

  onLocationChane = ( locations ) => {
    this.setState({ locations, tableSource: [] }, () => { this.refetch() });
  }

  onStateChange = ( states ) => {
    this.setState({ states, tableSource: [] }, () => { this.refetch() });
  }

  onOperChange = ( staff ) => {
    this.setState({ staff, tableSource: [] }, () => { this.refetch() });
  }

  onInactStaffChange = ( value ) => {
    this.setState({ showInactStaff: value });
  }

  disableDate = (current) => {
    const { currentDates } = this.state;

    const mgrLimit = () => {
      if (this.isMgr) {
        const today = moment();
        const minDate = today.clone().subtract(90, 'days');
        if (current.isBefore(minDate) || current.isAfter(today)) {
          return true;
        }
      }
      return false;
    }

    if ( currentDates ) {
      const from = currentDates[0];
      const to = currentDates[1];

      if (from) {
        const endOfMonth = from.clone().endOf('month');
        return current.isBefore(from) || current.isAfter(endOfMonth) || mgrLimit();
      } else if (to) {
        const startOfMonth = to.clone().startOf('month');
        return current.isBefore(startOfMonth) || current.isAfter(to) || mgrLimit();
      }
    }

    return mgrLimit();
  };

  SalesmanColumns = () => {
    const titles = [...new Set(get(this.props.viewer, 'salesTargetReport.data', []).map(item => item.title))];

    return (
      [
        {
          title: 'Staff Name',
          dataIndex: 'staff_name',
          key: 'staff_name',
          sorter: (a, b) => a.staff_name.localeCompare(b.staff_name),
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          sorter: (a, b) => (a.title ?? "").localeCompare(b.title ?? ""),
          filters: titles.map(title => ({
            text: title,
            value: title,
          })),
          onFilter: (value, record) => record.title === value,
        },
        {
          title: 'Sales',
          dataIndex: 'revenue',
          key: 'revenue',
          render: sales => (
            <div>
              <CurrencyFormatter amount={sales} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
          className: "ant-alert-warning"
        },
        {
          title: 'No. of Invoice',
          dataIndex: 'no_of_invoice',
          key: 'no_of_invoice',
          sorter: (a, b) => parseFloat(a.no_of_invoice) - parseFloat(b.no_of_invoice),
          className: "ant-alert-warning"
        },
        {
          title: 'Average Ticket Price',
          dataIndex: 'average_ticket_price',
          key: 'average_ticket_price',
          render: average => (
            <div>
              <CurrencyFormatter amount={average} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.average_ticket_price) - parseFloat(b.average_ticket_price),
          className: "ant-alert-warning"
        },
        {
          title: 'Branch Name',
          dataIndex: 'branch_name',
          key: 'branch_name',
          sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
        },
        {
          title: 'Date Started',
          dataIndex: 'start_date',
          key: 'start_date',
          sorter: (a, b) => a.start_date.localeCompare(b.start_date),
        },
        {
          title: 'Days Worked',
          dataIndex: 'days_worked',
          key: 'days_worked',
          sorter: (a, b) => parseFloat(a.days_worked) - parseFloat(b.days_worked),
        },
        {
          title: 'Daily Target',
          dataIndex: 'daily_target',
          key: 'daily_target',
          render: dailyTarget => (
            <div>
              <CurrencyFormatter amount={dailyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_target) - parseFloat(b.daily_target),
        },
        {
          title: 'Daily Average',
          dataIndex: 'daily_average',
          key: 'daily_average',
          render: monthlyTarget => (
            <div>
              <CurrencyFormatter amount={monthlyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_average) - parseFloat(b.daily_average),
        },
        {
          title: 'Percentage',
          dataIndex: 'pct',
          key: 'pct',
          render: text => typeof text === 'number' ? `${(parseFloat(text) * 100).toFixed(2)}%` : '0%',
          sorter: (a, b) => parseFloat(a.pct) - parseFloat(b.pct),
        },
        {
          title: 'Year_Month',
          dataIndex: 'year_month',
          key: 'year_month',
          sorter: (a, b) => a.year_month.localeCompare(b.year_month),
        },
        {
          title: 'State',
          dataIndex: 'state',
          key: 'state',
          sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
          title: 'Meet',
          dataIndex: 'meet',
          key: 'meet',
          sorter: (a, b) => parseFloat(a.meet) - parseFloat(b.meet),
        },
      ]
    )
  };

  StoreColumn = () => {
    const filteredTitles = [
      'Year_Month',
      'Branch Name',
      'State',
      'Daily Target',
      'Daily Average',
      'Sales',
      'Percentage',
      'Meet'
    ];

    return this.SalesmanColumns().filter(column => filteredTitles.includes(column.title));
  }

  prepareVariables = () => {
    const { locations, states, activeKey, staff } = this.state;
    const dateRangeFilter = [moment(this.dateRange[0]).startOf('day').utc().format(), moment(this.dateRange[1]).endOf('day').utc().format()].join(",");
    const filterBy = [
      {
        field: "inserted_at",
        filter: dateRangeFilter,
        filterType: "text",
        type: "inRange",
      },
    ];

    if (locations.length > 0) {
      filterBy.push({
        field: "branches",
        filter: locations.join(','),
        filterType: "text",
        type: "equals",
      })
    } else if (this.isMgr) {
      filterBy.push({
        field: "branches",
        filter: this.branchCodeMgr.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if (states.length > 0) {
      filterBy.push({
        field: "states",
        filter: states.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if (staff.length > 0) {
      filterBy.push({
        field: "staff",
        filter: staff.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    return {filterBy, tab: activeKey};
  }

  refetch = (date) => {
    if (this.fetchedDateRange && this.fetchedDateRange === date) {
      return;
    }
    const refetchVariables = this.prepareVariables();

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error)=> {
        if (error) {
          const e = get(error, '[0].message', 'Shit happened');
          message.error(e);
        }
        const tableSource = get(this.props.viewer, 'salesTargetReport.data', []);

        this.fetchedDateRange = date;

        this.setState({
          loading: false,
          tableSource,
        });

      },
      refetchVariables
    );
  }

  renderSelectStates = () => {
    const STATES = () => {
      const country = process.env.COUNTRY;

      if ( country === "NZ" ) {
        return ['NORTH ISLAND', 'SOUTH ISLAND'];
      }

      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
    }

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onStateChange}
        value={this.state.states}
        placeholder="Select a state"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.locations.length>0}
      >
        {STATES().map(node => (
          <Option key={node} value={node}>{node}</Option>
        ))}
      </Select>
    )
  }

  renderSelectStores = () => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onLocationChane}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.locations}
        placeholder="Select a store"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.states.length>0}
      >
        {this.stores.map(({node}) => {
          return <Option key={node.id} value={node.branchCode}>{node.branchCode === '18' ? `${node.name  } Commercial` : node.name}</Option>
        })}
      </Select>
    )
  }

  renderSelectStaff = () => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onOperChange}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.staff}
        placeholder="Select a staff"
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {this.staff.map((staff) => {
          return <Option key={staff.code} value={staff.code}>{staff.name}</Option>
        })}
      </Select>
    )
  }

  render() {
    const { activeKey, loading, showInactStaff, tableSource } = this.state;
    const rowStyles = {
      1: 'ant-alert-success',
      0: 'ant-alert-error',
    };

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <DatePicker.RangePicker
          ref={this.dateRangePickerRef}
          onChange={this.onDateChange}
          onCalendarChange={this.onCurrentDateChange}
          disabledDate={this.disableDate}
          value={this.dateRange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.refetch(this.dateRange);
              this.setState( {currentDates: null} );
            }
          }}
          renderExtraFooter={() => (
            <Button onClick={this.onClickThisMonth}>
              This Month
            </Button>
          )}
          timezone={TIMEZONE}
        />

        {!this.isMgr && this.renderSelectStates()}
        {this.renderSelectStores()}

        {activeKey ==='Salesman' && this.renderSelectStaff()}

        <Tabs activeKey={activeKey} onChange={(key) => { this.setState({ activeKey: key, tableSource: [] }, () => {this.refetch()}); }}>
          <TabPane tab="Store" key="Store">
            <Button style={{ marginBottom: '10px' }} onClick={this.onDownload}>
              Download
            </Button>
            <Table
              dataSource={tableSource.map((item, index) => ({ ...item, key: index }))}
              columns={this.StoreColumn()}
              loading={loading}
              pagination={false}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
              footer={() => (
                <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                  Total rows: {tableSource.length}
                </div>
              )}
            />
          </TabPane>

          <TabPane tab="Salesman" key="Salesman">
            <Button style={{ marginBottom: '10px' }} onClick={this.onDownload}>
              Download
            </Button>
            <div style={{marginBottom: '10px'}}>Show Inactive Staff: <Switch checked={showInactStaff} onChange={this.onInactStaffChange}  /></div>
            <Table
              dataSource={
                showInactStaff ? tableSource.map((item, index) => ({ ...item, key: index })) : tableSource.filter((item) => (item.days_worked > 0)).map((item, index) => ({ ...item, key: index }))
              }
              columns={this.SalesmanColumns()}
              loading={loading}
              pagination={{ position: "both", pageSize: 100 }}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
              size="small"
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default createRefetchContainer(
    SalesTargetReport, {
    viewer: graphql`
    fragment SalesTargetReport_viewer on Admin {
      salesTargetReport(filterBy: $filterBy, tab: $tab)
      roles(first: 99){
        edges {
          node {
            name
            defaultValues
          }
        }
      }
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            branchCode
            distributionHub
          }
        }
      }
      staff
    }
  `,
  },
  graphql`
    query SalesTargetReportRefetchQuery($filterBy: [FilterBy], $tab: String) {
      viewer {
        salesTargetReport(filterBy: $filterBy, tab: $tab)
      }
    }
  `,
);
