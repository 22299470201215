/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrandHistory_brand$ref = any;
type WebsiteRef_brand$ref = any;
export type UpdateBrandInput = {|
  description?: ?string,
  description2?: ?string,
  expressShipping?: ?boolean,
  id?: ?string,
  image?: ?any,
  metaDescription?: ?string,
  metaTitle?: ?string,
  name?: ?string,
  popular?: ?boolean,
  privateLabel?: ?boolean,
  searchScoreMultiplier?: ?number,
  status?: ?boolean,
  urlSlug?: ?string,
|};
export type UpdateBrandMutationVariables = {|
  input: UpdateBrandInput
|};
export type UpdateBrandMutationResponse = {|
  +updateBrand: ?{|
    +brand: ?{|
      +name: ?string,
      +imageUrl: ?string,
      +urlSlug: ?string,
      +status: ?boolean,
      +metaTitle: ?string,
      +metaDescription: ?string,
      +description: ?string,
      +description2: ?string,
      +expressShipping: ?boolean,
      +privateLabel: ?boolean,
      +searchScoreMultiplier: ?number,
      +popular: ?boolean,
      +$fragmentRefs: BrandHistory_brand$ref & WebsiteRef_brand$ref,
    |}
  |}
|};
export type UpdateBrandMutation = {|
  variables: UpdateBrandMutationVariables,
  response: UpdateBrandMutationResponse,
|};
*/


/*
mutation UpdateBrandMutation(
  $input: UpdateBrandInput!
) {
  updateBrand(input: $input) {
    brand {
      name
      imageUrl
      urlSlug
      status
      metaTitle
      metaDescription
      description
      description2
      expressShipping
      privateLabel
      searchScoreMultiplier
      popular
      ...BrandHistory_brand
      ...WebsiteRef_brand
      id
    }
  }
}

fragment BrandHistory_brand on Brand {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment WebsiteRef_brand on Brand {
  websiteReference {
    id
    country
  }
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description2",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expressShipping",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateLabel",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchScoreMultiplier",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "popular",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBrandMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBrandPayload",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BrandHistory_brand"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WebsiteRef_brand"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBrandMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBrandPayload",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "BrandHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WebsiteReference",
                "kind": "LinkedField",
                "name": "websiteReference",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d23f0cb3ea861d24b4ce7321b3eac039",
    "id": null,
    "metadata": {},
    "name": "UpdateBrandMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBrandMutation(\n  $input: UpdateBrandInput!\n) {\n  updateBrand(input: $input) {\n    brand {\n      name\n      imageUrl\n      urlSlug\n      status\n      metaTitle\n      metaDescription\n      description\n      description2\n      expressShipping\n      privateLabel\n      searchScoreMultiplier\n      popular\n      ...BrandHistory_brand\n      ...WebsiteRef_brand\n      id\n    }\n  }\n}\n\nfragment BrandHistory_brand on Brand {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment WebsiteRef_brand on Brand {\n  websiteReference {\n    id\n    country\n  }\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65a55567942332b4f911fc8ed37980a8';

module.exports = node;
